import React from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../containers/Hosting/Navbar';
import InfoHomeSection from '../containers/Hosting/InfoHome';
import InfoHomeAppSection from '../containers/Hosting/InfoHome/Applications';
import Scrollfixed from '../containers/Hosting/ScrollFixed';
import LinearSection from '../containers/Hosting/Linear';
import Ctasection from '../containers/Hosting/Ctasection';
import WorkBanner from '../containers/Hosting/Banner/WorkBanner';
import TestimonialSection from '../containers/Hosting/Testimonials';
import ComparisonSection from '../containers/Hosting/Comparison';
import Footer from '../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../components/homepageseo';
import Ipasection from '../containers/Hosting/HomeComponent/Ipasection';
import GlideSlider1 from '../containers/Hosting/GlideSlider';
import StatsSectionLight from '../containers/Hosting/Stats2';
import TopRibbon from '../containers/Hosting/TopRibbon';
import Whatifsection from '../containers/Hosting/Whatif/Whatifsection';
import Combination from '../containers/Hosting/CombinationContainer/Combination';
import Testimonial from '../containers/Hosting/TestimonialsSection/Testimonial';
import SuccessStories from '../containers/Hosting/SuccessStories/SuccessStories';
import HomeCTA from '../containers/Hosting/HomeCta/HomeCtasection';
import Popup from '../containers/Hosting/Popup/index';
import Background2 from "common/src/assets/image/hosting/ikons/star.png";

export default () => {
  const name = 'Home';
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Intelligent Process Automation Solutions"
          description="Rapid Acceleration Partners provides practical AI and Intelligent RPA solutions. Extract structured data from unstructured content. Book a demo here."
        />

        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper className="HomePage">
          <TopRibbon />
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            releasedClass="sticky-nav-inactive"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <WorkBanner />
         
        
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};
